<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div
      class="modal fade"
      id="modal_form_lista_bloques"
      style="background-color: #00000082"
    >
      <div class="modal-dialog modal-md">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left pt-2 pb-2">
            <h4 class="modal-title text-white">Generar listado Bloque</h4>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
              id="close-modal"
              ref="closeModal1"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <ul class="nav nav-tabs" id="myTab" role="tablist">
              <li class="nav-item">
                <a
                  class="nav-link active"
                  id="tab-Columnas"
                  data-toggle="tab"
                  href="#Columnas"
                  >Columnas</a
                >
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  id="tab-Filtros"
                  data-toggle="tab"
                  href="#Filtros"
                  >Filtros
                </a>
              </li>
            </ul>
            <div class="tab-content" id="myTabContent">
              <div class="tab-pane" id="Filtros">
                <div class="card-body">
                  <div class="form-group row">
                    <label for="nombre1" class="col-md-5">Nombre</label>
                    <input
                      type="text"
                      id="nombre1"
                      v-model="filtros.nombre"
                      class="col-md-7"
                    />
                  </div>
                  <div class="form-group row">
                    <label for="codigo1" class="col-md-5">Codigo</label>
                    <input
                      type="number"
                      id="codigo1"
                      v-model="filtros.codigo"
                      class="col-md-7"
                    />
                  </div>
                  <div class="form-group row">
                    <label for="ciudad_id" class="col-md-5">Ciudad</label>
                    <v-select
                      :class="$store.getters.getDarkMode ? 'dark-vselect' : ''"
                      v-model="ciudad"
                      placeholder="Ciudades"
                      label="nombre"
                      class="form-control form-control-sm p-0 col-md-7"
                      :options="listasForms.ciudades"
                      :filterable="true"
                      @input="getSelectCiudades()"
                    ></v-select>
                  </div>
                  <div class="form-group row">
                    <label for="departamento_id" class="col-md-5"
                      >Departamento</label
                    >
                    <v-select
                      :class="$store.getters.getDarkMode ? 'dark-vselect' : ''"
                      v-model="departamento"
                      placeholder="Departamentos"
                      label="descripcion"
                      class="form-control form-control-sm p-0 col-md-7"
                      :options="listasForms.departamentos"
                      :filterable="true"
                      @input="getSelectDepartamentos()"
                    ></v-select>
                  </div>
                  <div class="form-group row">
                    <label for="prefijo1" class="col-md-5">Prefijo</label>
                    <input
                      type="text"
                      id="prefijo1"
                      v-model="filtros.prefijo"
                      class="col-md-7"
                    />
                  </div>
                  <div class="form-group row">
                    <label for="estado" class="col-md-5">Estado</label>
                    <select
                      id="estado"
                      class="form-control form-control-sm col-md-7"
                      v-model="filtros.estado"
                    >
                      <option value="">Seleccione...</option>
                      <option
                        v-for="estado in listasForms.estados"
                        :key="estado.numeracion"
                        :value="estado.numeracion"
                      >
                        {{ estado.descripcion }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="tab-pane fade active show" id="Columnas">
                <div class="row">
                  <div class="col-lg-11">
                    <div class="form-group">
                      <div>
                        <div class="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            id="nombre"
                            v-model="form.nombre"
                            class="custom-control-input"
                          />
                          <label class="custom-control-label" for="nombre"
                            >Nombre</label
                          >
                        </div>
                      </div>
                      <div>
                        <div class="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            id="codigo"
                            v-model="form.codigo"
                            class="custom-control-input"
                          />
                          <label class="custom-control-label" for="codigo"
                            >Codigo</label
                          >
                        </div>
                      </div>
                      <div>
                        <div class="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            id="ciudad_id"
                            v-model="form.ciudad_id"
                            class="custom-control-input"
                          />
                          <label class="custom-control-label" for="ciudad_id"
                            >Ciudad</label
                          >
                        </div>
                      </div>
                      <div>
                        <div class="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            id="departamento_id"
                            v-model="form.departamento_id"
                            class="custom-control-input"
                          />
                          <label
                            class="custom-control-label"
                            for="departamento_id"
                            >Departamento</label
                          >
                        </div>
                      </div>
                      <div>
                        <div class="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            id="direccion"
                            v-model="form.direccion"
                            class="custom-control-input"
                          />
                          <label class="custom-control-label" for="direccion"
                            >Dirección</label
                          >
                        </div>
                      </div>
                      <div>
                        <div class="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            id="prefijo"
                            v-model="form.prefijo"
                            class="custom-control-input"
                          />
                          <label class="custom-control-label" for="prefijo"
                            >Prefijo</label
                          >
                        </div>
                      </div>
                      <div>
                        <div class="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            id="justificacion"
                            v-model="form.justificacion"
                            class="custom-control-input"
                          />
                          <label
                            class="custom-control-label"
                            for="justificacion"
                            >Justificación</label
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="modal-footer justify-content-between">
            <div>
              <button
                type="button"
                class="btn btn-sm bg-navy"
                @click="generarListadoExcel()"
                v-if="$store.getters.can('admin.bloques.export')"
              >
                Generar Listado
                <i class="fas fa-file-download"> </i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import vSelect from "vue-select";
import Loading from "../../../../components/Loading";
export default {
  name: "BloqueExport", //llegada tambien
  components: {
    Loading,
    vSelect,
  },
  data() {
    return {
      cargando: false,
      ciudad: {},
      departamento: {},

      form: {
        nombre: true,
        codigo: true,
        ciudad_id: true,
        departamento_id: true,
        direccion: true,
        justificacion: true,
        prefijo: true,
      },
      filtros: {
        nombre: null,
        codigo: null,
        ciudad_id: null,
        departamento_id: null,
        estado: null,
        prefijo: null,
      },
      listasForms: {
        lineas_negocio: [],
        ciudades: [],
        tipo_operacion: [],
        estados: [],
      },
    };
  },

  methods: {
    getSelectCiudades() {
      this.filtros.ciudad = {};
      this.filtros.ciudad_id = null;
      if (this.ciudad) {
        this.filtros.ciudad = this.ciudad;
        this.filtros.ciudad_id = this.ciudad.id;
      }
    },

    getSelectDepartamentos() {
      this.filtros.departamento = {};
      this.filtros.departamento_id = null;
      if (this.departamento) {
        this.filtros.departamento = this.departamento;
        this.filtros.departamento_id = this.departamento.id;
      }
    },

    async getCiudades() {
      await axios.get("/api/admin/ciudades/lista").then((response) => {
        this.listasForms.ciudades = response.data;
      });
    },

    async getDepartamentos() {
      await axios.get("/api/admin/departamentos/lista").then((response) => {
        this.listasForms.departamentos = response.data;
      });
    },

    getEstados() {
      axios.get("/api/lista/1").then((response) => {
        this.listasForms.estados = response.data;
      });
    },

    generarListadoExcel() {
      this.cargando = true;
      axios({
        method: "POST",
        url: "/api/admin/bloques/export",
        data: { form: this.form, filtros: this.filtros },
      })
        .then((response) => {
          this.cargando = false;
          let data = response.data;
          location.href = data.url;
        })
        .catch((e) => {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          this.cargando = false;
        });
    },
  },

  async mounted() {
    this.cargando = true;
    await this.getCiudades();
    await this.getDepartamentos();
    await this.getEstados();
    this.cargando = false;
  },
};
</script>
